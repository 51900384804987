import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import Content, {HTMLContent} from '../components/Content'
import Hero from '../components/Hero'
import ContactForm from '../components/ContactForm'

export const ServiceTemplate = ({content, contentComponent, description, title, helmet}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <PostContent content={content}/>
          </div>
        </div>
      </div>
    </section>)
}

ServiceTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.instanceOf(Helmet)
}

const Service = ({data}) => {
  const post = data.post

  return (<Layout>

    <Hero headerimg={post.frontmatter.image.childImageSharp.fluid}/>
    <div class="secondary-contain">
    <ServiceTemplate content={post.html} contentComponent={HTMLContent} description={post.frontmatter.description} helmet={<Helmet title = {
        `${post.frontmatter.title} | Blog`
      } />} tags={post.frontmatter.tags} title={post.frontmatter.title}/>
    <ContactForm contactdata={data.contactcontent}/>
  </div>
  </Layout>)
}
Service.propTypes = {
  data: PropTypes.shape({markdownRemark: PropTypes.object})
}

export default Service

export const pageQuery = graphql ` query ServiceByID($id: String!) {
    post: markdownRemark(id : {
      eq: $id
    }) {
      id
      html
      frontmatter {
        date(formatString : "MMMM DD, YYYY")
        title
        description
        image {
          childImageSharp {
            fluid(maxWidth : 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    contactcontent: markdownRemark(frontmatter : {
      path: {
        eq: "/contact"
      }
    }) {
      id
      frontmatter {
        path
        title
        cta
        image {
          childImageSharp {
            fluid(maxWidth : 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
  }
  `
